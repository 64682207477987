export const baseUrl = "https://node-gyanafrica.mobiloitte.io/api/v1";
// export const baseUrl = "https://node.gyanbatua.com/api/v1";
// export const baseUrl = "http://172.16.1.226:2064/api/v1" ;
// export const baseUrl  = "http://172.16.2.35:2055/api/v1" //aniket sir local
// export const baseUrl = "http://172.16.1.211:2055/api/v1"; // punit
// export const baseUrl = "http://172.16.2.35:2055";
// export const baseUrl = "http://172.16.1.226:2055/api/v1"; //anurag sir local/
const coePartner = `${baseUrl}/coePartners`
const user = `${baseUrl}/user`;
const course = `${baseUrl}/course`;
const watchlist = `${baseUrl}/watchlist`;
const skill = `${baseUrl}/skill`;
const certificate = `${baseUrl}/certificate`;
const notification = `${baseUrl}/notification`;
const ticket = `${baseUrl}/ticket`; 
const cart = `${baseUrl}/cart`;
const payment = `${baseUrl}/payment`;
const price = `${baseUrl}/price`;
const referalcode = `${baseUrl}/referalcode`; 
const seo = `${baseUrl}/courseseo`;
const Categoryseo = `${baseUrl}/categoryseo`; 
const SubCategoryseo = `${baseUrl}/subcategoryseo`; 



export const apiConfig = {
  coePartnerList:`${coePartner}/coePartnerList`,
  viewCoePartner:`${coePartner}/viewCoePartner`,
  priceDataList: `${price}/priceDataList`,
  removeWatchlist: `${watchlist}/removeWatchlist`,
  register: `${user}/register`,
  signUpBounty: `${user}/signUpBounty`,
  courseBounty:`${user}/courseBounty`,
  login: `${user}/login`,
  newGyanis: `${user}/newGyanis`,
  globalSearchApi: `${user}/globalSearchApi`,
  forgotPassword: `${user}/forgotPassword`,
  verify_email_otp: `${user}/verify_email_otp`,
  resend_otp_email: `${user}/resend_otp_email`,
  resetPassword: `${user}/resetPassword`,
  listTrendingCourse: `${course}/listTrendingCourse`,
  uploadIpfs: `${course}/uploadIpfs`,
  getUserProfile: `${user}/profile`,
  uploadIpfsImage: `${user}/uploadIpfsImage`,

  updateUserProfile: `${user}/updateProfile`,
  searchUserWithSkills: `${user}/searchUserWithSkills`,
  getAllCategory: `${course}/getAllCategory`,
  transactionHBD: `${user}/transactionHBD`,
  createResumeUsingAi: `${user}/createResumeUsingAi`,
  listallResume: `${user}/listallResume`,
  getResume: `${user}/getResume`,
  deleteResume: `${user}/deleteResume`,
  getAllSubCategory: `${course}/getAllSubCategory`,
  getDetails: `${user}/getDetails`,
  checkWatchlistStatus: `${user}/checkWatchlistStatus`,
  filterCourse: `${course}/filterCourse`,
  getTrendingSubCategory: `${course}/getTrendingSubCategory`,
  getSpotlightSubCategory: `${course}/getSpotlightSubCategory`,
  subscribeEmail: `${user}/subscribe`,
  userBalance: `${user}/userBalance`,
  listWatchList: `${watchlist}/listWatchList`,
  listOwnWatchList: `${watchlist}/listOwnWatchList`,
  updateStatusWatchlist: `${watchlist}/updateStatusWatchlist`,
  transaction: `${user}/transaction`,
  skillList: `${skill}/skillsList`,
  subSkillList: `${skill}/subSkillsList`,
  uploadImage: `${user}/uploadImage`,
  addCertificate: `${certificate}/addCertificate`,
  certificateStatus: `${certificate}/certificateStatus`,
  socialLogin: `${user}/socialLogin`,
  getUserBadge: `${user}/getUserBadge`,
  certificateList: `${certificate}/certificateList`,
  approveRejectCertificated: `${certificate}/approveRejectCertificate`,
  getAllCoursesBySubCategory: `${course}/getAllCoursesBySubCategory`,
  changePassword: `${user}/changePassword`,
  listCourse: `${course}/listCourse`,
  getCourse: `${course}/getCourse`,
  createCourse: `${course}/createCourse`,
  listNotification: `${notification}/listNotification`,
  deleteNotification: `${notification}/deleteNotification`,
  clearNotification: `${notification}/clearNotification`,
  addResume: `${user}/addResume`,
  sendMobileOtp: `${user}/resend_otp_mobile`,
  mobileOtpVerification: `${user}/mobileOtpVerification`,
  addProfilePic: `${user}/addProfilePic`,
  ticketEnquiryList: `${ticket}/ticketEnquiryList`,
  addTicketEnquiry: `${ticket}/addTicketEnquiry`,
  viewTicketEnquiry: `${ticket}/viewTicketEnquiry`,
  addToCart: `${cart}/addToCart`,
  addBundleToCart: `${cart}/addBundleToCart`,

  addBundleToCart:`${cart}/addBundleToCart`,
  viewCart: `${cart}/viewCart`,
  removeFromCart: `${cart}/removeItemFromCart`,
  getAllDropCourse: `${user}/getAllDropCourse`,
  getDetails: `${user}/getDetails`,
  transactionHBD: `${user}/transactionHBD`,
  myCourse: `${course}/myCourse`,
  scanCode: `${user}/scanCode`,
  recomendCourse: `${course}/recomendCourse`,
  incrementView: `${course}/incrementView`,

  
  recomendCourseForOther: `${course}/recomendCourseForOther`,
  viewTemplate: `${course}/viewTemplate`,
  partnerForm: `${baseUrl}/landingUniversity/addLandingUniversity`,
  checkEmailExists: `${user}/checkEmailExist`,
  createCourseEducator: `${course}/createCourseEducator`,
  //payment
  checkOut: `${payment}/checkOut`,
  paymentVerification: `${payment}/paymentVerification`,
  bundlePaymentVerify: `${payment}/bundlePaymentVerify`,


  getTransactionId: `${payment}/getTransaction`,
  checkOutCart: `${payment}/checkOutCart`,
  checkOutBundleInCart: `${payment}/checkOutBundleInCart`,

  

  // add watchlist
  addWatchlist: `${watchlist}/addWatchList`,



  viewRefralCode :`${referalcode}/viewReferalCode`, 
  checkReferalCode :`${referalcode}/checkReferalCode`, 


  viewCourseSEO :`${seo}/viewCourseSEO`, 
  viewCategorySEO:`${Categoryseo}/viewCategorySEO`,
  viewSubCategorySEO:`${SubCategoryseo}/viewSubCategorySEO`,

};
