import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { useMediaQuery } from "@material-ui/core";

import {
  Grid,
  Box,
  Typography,
  makeStyles,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Button,
  CircularProgress,
  FormHelperText,
  Dialog,
  useTheme,
} from "@material-ui/core";

// import { UserContext } from 'src/context/User'
import { useHistory, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import { apiConfig } from "src/apiconfig/ApiConfig";
import toast from "react-hot-toast";
import { Category } from "@material-ui/icons";
import { ImCross } from "react-icons/im";

const defaultCategories = [
  "Health and Medicine",
  "Engineering and Technology",
  "Business and Finance",
  "Arts and Design",
  "Education and Training",
  "Information Technology",
  "Legal Services",
  "Trades and Services",
  "Agriculture and Environmental Sciences",
  "Media and Communication",
  "Sports and Fitness",
  "Hospitality and Tourism",
  "Public Service and Government",
  "Research and Development",
  "Arts and Creative Professions",
];
const useStyles = makeStyles((theme) => ({
  footerSection: {
    // background: "#161E29",
    position: "relative",
    padding: "19px 0px 0",
    zIndex: "2",
    background: "rgba(8, 86, 204, 0.03)",

    // overflow: " hidden",
    "& .subsribeBtn": {
      width: "100%",
      maxWidth: "154px",
    },
    "& .Gimages": {
      width: "364px",
      marginLeft: "-4px",
      "& .googlePlay": {
        width: "45%",
        height: "60px",
        "& img": {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        alignItems: "start",
        width: "296px",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "row",
        alignItems: "start",
        width: "280px",
      },
    },
    "& .communityContainer": {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      // maxWidth: "1550px",
      margin: "0 auto 18px auto",
      "& .SubcommunityContainer":{
        width:'70%',
        "@media (max-width:480px)":{
          width:"100%"
        }
      },
      [theme.breakpoints.down("xs")]: {
        margin: "0 auto 18px auto",
      },
      padding: "23px 0",
      background: "#fff",
      borderRadius: "20px",
      width: "calc(100% - 100px)",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "20px",
        padding: "23px",
      },

      "& .textdata": {
        maxWidth: "800px",

        "& h3": {
          fontSize: "30px",
          fontWeight: 500,
          [theme.breakpoints.down("xs")]: {
            fontSize: "24px !important",
            lineHeight: "28px !important",
            paddingBottom: "15px",
          },
        },

        "& p": {
          color: theme.palette.text.primary,
          // marginTop: "6px",
          width: "100%",
          maxWidth: "681px",
          fontSize: "14px",
        },

        "& .textfieldAndButton": {
          marginTop: "16px",

          "& .emailTextfield": {
            width: "100%",
            maxWidth: "336px",
            marginRight: "10px",

            [theme.breakpoints.down("md")]: {
              width: "55%",
            },

            "& .MuiOutlinedInput-root": {
              height: "44px",
            },
          },

          "& button": {
            height: "44px",
            width: "100%",
            maxWidth: "153px",
            [theme.breakpoints.down("xs")]: {
              width: "30%",
              fontSize: "14px",
            },

            // "@media(max-width:599px)": {
            //   marginTop: "12px",
            // },
          },
        },
      },

      "& .joinOurCommunity": {
        "& h3": {
          marginBottom: "21px",
          fontSize: "30px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "24px!important",
          },
        },

        "& .iconContainer": {
          [theme.breakpoints.down("xs")]: {
            width: "100%",
            display: "flex",
          },
        },

        "& .iconButton": {
          padding: "0",
          width: "40px",
          height: "40px",
          border: "1px solid #DDD",
          borderRadius: "3px",
          background: "#FFF",
          marginRight: "10px",
          [theme.breakpoints.down("xs")]: {
            marginRight: "4px",
            width: "30px",
            height: "30px",
          },
        },

        "& .messangerIconButton": {
          position: "absolute",
          top: "-60px",
          display: "none",

          [theme.breakpoints.down("md")]: {
            right: "0",
          },

          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },

    "& .footerContent": {
      "& .blanckDisplay": {
        "@media(max-width:959px)": {
          display: "none",
        },
      },
      "@media(min-width:1280px)": {
        " & .MuiGrid-container": {
          flexWrap: "nowrap",
        },
      },
      "& .footerSmallContainer": {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        padding: "0 16px",
        rowGap: "16px",
        [theme.breakpoints.down("xs")]: {
          padding: "16px",
        },
      },
      "& .getApp": {
        marginTop: "-120px",
        [theme.breakpoints.down("sm")]: {
          margin: "0",
          padding: "16px",
        },
      },
      boxSizing: "border-box",
      // width: "100%",
      // maxWidth: "1550px",
      margin: "0 auto",
      background: "#fff",
      padding: "40px",
      width: "calc(100% - 100px)",
      borderRadius: "20px",
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 50px) !important",
      },
      [theme.breakpoints.down("md")]: {
        width: "calc(100% - 50px) !important",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12px",
      },
    },
    "& .gyanbatualogo": {
      width: "80px",
      marginRight: "8px",
      cursor: "pointer",
      // [theme.breakpoints.down("xs")]: {
      //   display: "none",
      // },
    },

    "& .trademarks": {
      color: "#262626",
      fontSize: "14px",
      fontWeight: 400,
      // [theme.breakpoints.down("xs")]: {
      //   display: "none",
      // },
      "& span": {
        cursor: "pointer",
        color: "#0856CC",
      },
    },
    "& .footerContentBox": {
      "& .aboutgyan": {
        color: "rgba(99, 104, 128, 1)",
        fontFamily: "Roboto Condensed",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
      },
      "& .logoContainer": {
        [theme.breakpoints.down("xs")]: {
          display: "flex",
          justifyContent: "center",
        },
      },

      "& p": {
        fontFamily: "Calistoga",
        color: theme.palette.background.default,
        letterSpacing: "0.478px",
      },
      "& .logosideSubHeading": {
        fontSize: "7.785px",
        color: "rgba(255, 255, 255, 0.60)",
        fontFamily: "Outfit",
      },
      "& .footBelowLogoTypo": {
        fontWeight: 300,
      },
      "@media(max-width:1280px)": {
        maxWidth: "269px",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
      "& h6": {
        color: "rgba(255,255,255,0.60)",
        fontSize: "16px",
        fontWeight: 300,
      },
    },
    "& .secondFooterGrid": {
      display: "flex",
      height: "100%",
      // gap: "12px",
      width: "100%",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
      },

      "& h5": {
        marginBottom: "12px",
        fontFamily: "Roboto Condensed",
        fontSize: "18px !important",
        fontWeight: 500,
        lineHeight: "21.09px",
      },

      "& h6": {
        cursor: "pointer",
      },
      "& p": {
        color: "#575757",
        cursor: "pointer",
        lineHeight: "40px",
      },
      "& a": {
        color: "#575757",
        fontSize: "16px",
      },
    },
    "& .fourthFooterGrid": {
      marginRight: "35px",
      display: "flex",
      height: "100%",
      gap: "6px",
      width: "100%",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
      },
      "& h5": {
        cursor: "pointer",
        fontFamily: "Roboto Condensed",
        fontSize: "18px !important",
        fontWeight: 500,
        lineHeight: "21.09px",
      },
      "& .boxcont": {
        color: "#575757",
      },
    },
    "& .contactUsBox": {
      "& h5": {
        fontFamily: "Calistoga",
        color: theme.palette.background.default,
        fontWeight: 400,
      },
      "& a": {
        marginBottom: "6px",
        color: "rgba(255, 255, 255, 0.60)",
        fontFamily: "Outfit",
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "20px",
      },
    },
    "& .copy": {
      fontSize: "12px",
      textAlign: "center",
      fontWeight: "300",
      "& .lowerFooter": {
        flexWrap: "wrap",
        gap: "15px",
        position: "relative",
        padding: "14px 0px",
        "& > div:first-child": {
          display: "flex",
          gap: "12px",
          "& p": {
            fontWeight: "300 !important",
            color: "rgba(255, 255, 255, 0.60)",
          },
        },
        "& > div:nth-child(2)": {
          color: "rgba(255, 255, 255, 0.60)",
          fontFamily: "Sora",
          fontSize: "15.743px",
          fontWeight: 300,
          "& span": {
            fontWeight: 400,
            color: "#fff",
            fontFamily: "Calistoga",
          },
        },
      },
    },

    "& ul": {
      paddingLeft: "0",
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: theme.palette.text.gray,
        fontSize: "14px",
        fontWeight: "300",
        display: "block",
        "& svg": {
          marginRight: "10px",
          color: "#fe2efe",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "rgba(255 255 255 / 30%)",
      fontSize: "15px",
    },
    "& p": {
      color: theme.palette.text.gray,
    },
    "& h6": {
      color: "#000000",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },

    "& a": {
      display: "flex",
      fontSize: "13px",
      alignItems: "center",
      fontWeight: "400",
      paddingLeft: "0px",
      paddingRight: "0px",
      textDecoration: "none",
      color: "#78819F",
      padding: "3px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "11px",
      },
      "&:hover": {
        color: "#EC1F24",
        textDecoration: "none",
        "& svg": {
          color: "red",
          fontSize: "15px",
        },
      },
    },
    "& .borderBox": {
      position: "absolute",
      left: "153px",
      top: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .bottomFootertext": {
      width: "calc(100% - 100px)",
      margin: "26px auto 0 auto",
      textAlign: "center",
      paddingBottom: "18px",
    },
  },

  iconbtn: {
    marginTop: "20px",
    "& img": {
      marginTop: "10px",
      marginRight: "30px",
    },
    "& .MuiIconButton-root": {
      marginRight: "8px",
      marginBottom: "8px",
      borderRadious: "10px",
      borderRadius: "7px",
      width: "30px",
      height: "30px",
      padding: "0px",

      "& svg": {
        color: "#395071",
        fontSize: "24px",
        "& :hover": {
          "& svg": {
            color: "#EC1F24",
            fontSize: "24px !important",
          },
        },
      },
    },
  },
  displayCenetr: {
    minWidth: "100px",
    display: "flex",
    justifyContent: "left",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
    "& .MuiSelect-icon": {
      fontSize: "20px",
      color: "#262626 !important",
    },

    "& .MuiSelect-select": {
      border: "none",
    },

    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },

    "& .MuiInputBase-input": {
      fontSize: "16px",
      color: "#262626!important",
      fontWeight: 500,
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },

    "& .selectContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
      "& > *:first-child": {
        margin: "0 16px 0 0",
      },
    },
  },
  dialogPaper: {
    width: "100%",
    height: "100%",
    maxWidth: "682px",
    maxHeight: "507px",
    borderRadius: "5px",
    "& .passDialogBox": {
      padding: "0px 50px 20px",
    },
    "& .crossIcon": {
      position: "absolute",
      right: "20px",
      paddingTop: "13px",
      cursor: "pointer",
    },
    "& .image1": {
      // mixBlendMode: "hard-light"
    },
    "& .subTypoChangePass": {
      fontFamily: "Roboto Condensed",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "center",
      width: "100%",
      maxWidth: "333px",
    },
    "& .btnOKsubmit": {
      width: "100%",
      // height: 'auto',
      maxWidth: "120px",
      // maxHeight:"45px",
      fontFamily: "Roboto Condensed",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "19px",
    },
    "& .typoChangePass": {
      color: "#262626",
      fontSize: "35px",
      lineHeight: "41px",
      fontWeight: 500,
    },
  },
}));

const formInitialSchema = {
  email: "",
};

const formValidationSchema = Yup.object({
  email: Yup.string()
    .email("Email is Invalid")
    .max(256, "Email must be at most 256 characters")
    .required("Email is required.")
    .matches(
      // /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,256}$/,
      /^(?!.*\.\.)(?!.*[@.]$)[a-zA-Z0-9][a-zA-Z0-9._+-]{0,252}@(?=[^.]*[A-Za-z])[a-zA-Z0-9-]{2,63}\.[a-zA-Z]{2,63}$/,
      "Invalid email address."
    ),
});

export default function Footer() {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [allCategory, setAllCategory] = useState([]);
  // const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscribeUser, setSubscribeUser] = useState();
  const [emailError, setEmailError] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleErrorModalOpen = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };
  const handleMarketplace = (event) => {
    const categoryId = event.target.value;
    const selectedCategory = allCategory.find(
      (category) => category._id === categoryId
    );
    if (selectedCategory) {
      history.push({
        pathname: `/category/${selectedCategory.categoryName
          .replace(/\s+/g, "-")
          .toLowerCase()}`,
        state: {
          categoryId: selectedCategory._id,
          categoryName: selectedCategory.categoryName,
          description: selectedCategory.description,
          bannerImage: selectedCategory.bannerImage,
          categoryImage: selectedCategory.categoryImage,
        },
      });
    }
  };

  // const handleModalClose = () => {
  //   setModalOpen(false);
  // };

  const handleTerms = () => {
    history.push("/terms-conditions");
  };

  const handlePrivacy = () => {
    history.push("/privacy");
  };

  const handleCookies = () => {
    history.push("/cookies-policy");
  };
  const handleRanking = () => {
    history.push("/ranking");
    window.scrollTo(0, 0);
  };
  const handleActivity = () => {
    history.push("/activity");
    window.scrollTo(0, 0);
  };

  const handleCommunity = () => {
    history.push("/community");
    window.scrollTo(0, 0);
  };

  const handleLightpaper = () => {
    history.push("/lite-paper");
    window.scrollTo(0, 0);
  };

  const handlWhitepaper = () => {
    history.push("/white-paper");
    window.scrollTo(0, 0);
  };

  const handlePlatform = () => {
    history.push("/platform-specifics");
    window.scrollTo(0, 0);
  };
  const handleTax = () => {
    history.push("/tax-fee");
    window.scrollTo(0, 0);
  };
  const handlePrivacyPolicy = () => {
    history.push("/privacy-policy");
    window.scrollTo(0, 0);
  };
  const handlePrivacyPolicy1 = () => {
    history.push("/policy");
    window.scrollTo(0, 0);
  };
  const handleTermCondition = () => {
    history.push("/terms-conditions");
    window.scrollTo(0, 0);
  };
  const handleDataPrivacy = () => {
    history.push("/data-privacy");
    window.scrollTo(0, 0);
  };
  const handleCategoryClick = (item) => {
    history.push({
      pathname: `/categories/${item?.categoryName
        .replace(/\s+/g, "-")
        .toLowerCase()}`,
      state: {
        categoryId: item?._id,
        categoryName: item?.categoryName,
        description: item?.description,
        bannerImage: item?.bannerImage,
        categoryImage: item?.categoryImage,
      },
    });
    window.scrollTo(0, 0);
  };

  const handleFAQ = () => {
    history.push("/faq");
    window.scrollTo(0, 0);
  };

  const handleDrop = () => {
    history.push("/skill-drops");
    window.scrollTo(0, 0);
  };

  const handleBlogClick = () => {
    history.push("/blog");
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFaq = () => {
    history.push("/faq", { value: 1 });
    window.scrollTo(0, 0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubScribeClick = async (values, resetForm) => {
    if (values.email === "") {
      return;
    }
    let formData = new FormData();
    formData.append("email", values.email);
    try {
      setIsLoading(true);
      const res = await axios.post(apiConfig.subscribeEmail, {
        email: values.email,
      });

      if (res?.data?.responseCode === 200) {
        setSubscribeUser(res?.data?.responseMessage);
        handleModalOpen();
        resetForm();
      }
      if (res?.data?.responseCode === 409) {
        setEmailError(res?.data?.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      setEmailError("Already subscribed.");
      handleErrorModalOpen();
    }
  };

  const getAllCategory = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfig.getAllCategory,
      });

      if (res?.data?.responseCode === 200) {
        setAllCategory(res?.data?.result?.docs);
      }
    } catch (error) {
      if (error?.response) {
        toast.error(error?.response?.data?.responseMessage);
      }
    }
  };
  const handleBatuaClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/my-wallet");
    } else {
      history.push("/login");
    }
  };

  const handlewatchClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/watchlist");
    } else {
      history.push("/login");
    }
  };

  const isExtraSmallScreen = useMediaQuery((theme) =>
    theme.breakpoints.down("xs")
  );

  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <>
      <Box className={classes.footerSection}>
        <Box className="communityContainer">
          <Box className="SubcommunityContainer">
            <Box className="textdata">
              <Typography variant="h3" color="secondary">
                Don't Miss Out! Join Our Drops & New Courses List!
              </Typography>
              <Typography variant="body2">
                Subscribe now to receive notifications about free course drops
                and new courses available on Skills Marketplace. Stay updated
                with our latest activities, newsletters, and more content
                tailored to your interests.
              </Typography>
              <Formik
                initialValues={formInitialSchema}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={formValidationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubScribeClick(values, resetForm);
                  setSubmitting(false);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <Box className="textfieldAndButton">
                      <TextField
                        variant="outlined"
                        placeholder="Your email address"
                        className="emailTextfield"
                        autoComplete="off"
                        name="email"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={(e) => {
                          handleChange(e);
                          setEmailError("");
                        }}
                        InputProps={{
                          autoComplete: "off",
                        }}
                      />
                      <Button
                        variant="containedPrimary"
                        className="subscribeBtn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Subscribe
                        {loading && (
                          <CircularProgress color="secondary" size="small" />
                        )}
                      </Button>
                    </Box>
                    {touched.email && errors.email && (
                      <FormHelperText error style={{ color: "red" }}>
                        {errors.email}
                      </FormHelperText>
                    )}
                    {emailError && (
                      <FormHelperText error style={{ color: "red" }}>
                        {emailError}
                      </FormHelperText>
                    )}
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <Dialog
            open={isModalOpen}
            onClose={handleModalClose}
            classes={{ paper: classes.dialogPaper }}
          >
            <Box className="passDialogBox">
              <ImCross className="crossIcon" onClick={handleModalClose} />
              <Box className="displayCenter">
                <img
                  src="/images/subscribeImage.png"
                  alt=""
                  className="image1"
                />
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  className="typoChangePass displayCenter"
                >
                  {/* {subscribeUser}{" "}! */}
                  Thank you for Subscribing!
                </Typography>
              </Box>
              <Box mt={3} className="displayCenter">
                <Typography variant="body2" className="subTypoChangePass">
                  {" "}
                  You have successfully subscribed to our list.
                </Typography>
              </Box>
              <Box className="displayCenter" pt={3}>
                {/* <Button
                    variant="containedPrimary"
                    className="btnOKsubmit"
                    onClick={handleModalClose}
                  >
                    {" "}
                    Okay{" "}
                  </Button> */}
              </Box>

              <Box mt={4} className="displayCenter">
                <img src="/images/logo.svg" alt="" height="50px" />
              </Box>
            </Box>
          </Dialog>
          <Dialog
            open={isErrorModalOpen}
            onClose={handleErrorModalClose}
            classes={{ paper: classes.dialogPaper }}
            // PaperProps={{ style: { width: "380px", maxHeight: "380px" } }}
          >
            <Box className="passDialogBox">
              <ImCross className="crossIcon" onClick={handleErrorModalClose} />
              <Box className="displayCenter" style={{ marginTop: "40px" }}>
                <img
                  src="/images/subscribeImage.png"
                  alt=""
                  className="image1"
                  // style={{ width: "150px", height: "120px" }}
                />
              </Box>
              <Box mb={2}>
                <Typography
                  variant="h4"
                  className="typoChangePass displayCenter"
                >
                  You are already subscribed!
                </Typography>
              </Box>
              <Box mt={3} className="displayCenter">
                <Typography variant="body2" className="subTypoChangePass">
                  You are already on our subscription list. Thank you!{" "}
                </Typography>
              </Box>
              <Box className="displayCenter" pt={3}>
                {/* <Button
                    variant="containedPrimary"
                    className="btnOKsubmit"
                    onClick={errorModelClose}
                  >
                    {" "}
                    Okay{" "}
                  </Button> */}
              </Box>

              <Box mt={4} className="displayCenter">
                <img src="/images/logo.svg" alt="" height="50px" />
              </Box>
            </Box>
          </Dialog>
          <Box className="joinOurCommunity">
            <Typography variant="h3" color="secondary">
              Join the community
            </Typography>

            <Box className="iconContainer">
              <IconButton
                className="iconButton"
                onClick={() =>
                  window.open("https://www.facebook.com/gyaanbatua/", "_blank")
                }
              >
                <img src="/images/fb.svg" alt="Facebook" />
              </IconButton>

              <IconButton
                className="iconButton"
                onClick={() =>
                  window.open("https://twitter.com/GyanBatua", "_blank")
                }
              >
                <img src="/images/twitter_footer.svg" alt="Twitter" />
              </IconButton>

              <IconButton
                className="iconButton"
                onClick={() =>
                  window.open("https://www.instagram.com/gyanbatua/", "_blank")
                }
              >
                <img src="/images/insta_footer.svg" alt="Instagram" />
              </IconButton>

              <IconButton
                className="iconButton"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/gyan-batua/",
                    "_blank"
                  )
                }
              >
                <img src="/images/linkedIn_footer.svg" alt="LinkedIn" />
              </IconButton>

              <IconButton
                className="iconButton"
                onClick={() =>
                  window.open("https://www.youtube.com/@Gyan-Batua", "_blank")
                }
              >
                <img src="/images/yt_footer.svg" alt="YouTube" />
              </IconButton>

              <IconButton
                className="iconButton"
                onClick={() =>
                  window.open("https://in.pinterest.com/GyanBatua/", "_blank")
                }
              >
                <img src="/images/pinterest_footer.svg" alt="Pinterest" />
              </IconButton>

              <IconButton
                className="messangerIconButton"
                onClick={() =>
                  window.open("https://www.messenger.com", "_blank")
                }
              >
                <img src="/images/messanger.svg" alt="Messenger" />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Box className="footerContent">
          <Grid container spacing={4}>
            {/* =====================image and text=======================  */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6} // md={3}
              lg={8}
              className={classes.displayCenetr}
              style={{ justifyContent: "start" }}
            >
              <Box
                className="footerContentBox"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Box mb={4} className="logoContainer">
                    <RouterLink to="/">
                      <img src="/images/logo.svg" alt="" />
                    </RouterLink>
                  </Box>
                  <Typography
                    variant="outlined"
                    style={{}}
                    className="aboutgyan"
                  >
                    Gyan Batua is redefining matchmaking for skills. Create your
                    own Skills Wallet, Generate you own unique QR code, and
                    maintain a Live Resume. Earn while you Learn, Get noticed,
                    Showcase your talents, and Trade your Abilities Seamlessly.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/*======================= my account ======================= */}
            <Grid
              item
              xs={0}
              sm={0}
              md={1}
              lg={6}
              xl={6}
              className="blanckDisplay"
            ></Grid>
            <Grid
              item
              xs={5}
              sm={4}
              md={2}
              lg={2}
              className={classes.displayCenetr}
            >
              <Box className="secondFooterGrid">
                <Typography variant="h5">Company</Typography>
                <Typography
                  variant="body2"
                  onClick={() => history.push("/about-us")}
                >
                  About us
                </Typography>

                <Typography
                  variant="body2"
                  onClick={() => history.push("/media")}
                >
                  Media
                </Typography>
                <Typography variant="body2" onClick={handleBlogClick}>
                  Blog
                </Typography>
              </Box>
            </Grid>

            {/*=======================  Resources ======================= */}
            {
              <>
                <Grid
                  item
                  xs={7}
                  sm={6}
                  md={3}
                  lg={2}
                  xl={2}
                  className={classes.displayCenetr}
                >
                  <Box className="secondFooterGrid">
                    <Typography variant="h5">Resources </Typography>
                    <Box style={{ display: "flex", justifyContent: "start" }}>
                      <Box style={{ marginRight: "20px" }}>
                        <Typography
                          variant="body2"
                          onClick={() => history.push("/Existing-COE-Partners")}
                        >
                          Partners
                        </Typography>
                        <Typography variant="body2" onClick={handleFaq}>
                          FAQs
                        </Typography>
                        <Typography
                          variant="body2"
                          onClick={handlePrivacyPolicy1}
                        >
                          Policies
                        </Typography>
                        {/* <Typography
                          variant="body2"
                          onClick={handlePrivacyPolicy}
                        >
                          Privacy  Policy
                        </Typography>
                        <Typography
                          variant="body2"
                          onClick={handleTermCondition}
                        >
                          Terms and Conditions
                        </Typography>
                        <Typography variant="body2" onClick={handleDataPrivacy}>
                          Data Privacy
                        </Typography> */}
                      </Box>

                      {/* <Box>
                            <Typography variant="body2" onClick={handlePrivacyPolicy}>
                              Privacy  Policy
                            </Typography>
                            <Typography variant="body2" onClick={handleTermCondition}>
                              Terms and Conditions
                            </Typography>
                            <Typography variant="body2" onClick={handleDataPrivacy}>
                              Data Privacy
                            </Typography>
                          </Box> */}
                    </Box>
                  </Box>
                </Grid>
              </>
            }
          </Grid>
        </Box>

        <Box className="displayCenter bottomFootertext">
          <RouterLink to="/">
            <img src="/images/logo.svg" className="gyanbatualogo"></img>
          </RouterLink>

          <Typography className="trademarks">
            <span
              onClick={() =>
                window.open("https://www.mobiloitte.com/", "_blank")
              }
            >
              © 2024 Mobiloitte Inc.
            </span>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
